import styles from './style.css';
import {MAIN_EMAIL, MAIN_PHONE, SECONDARY_PHONE} from "../../constants/general";
const FooterComponent = () => {
    return <footer className={styles.footer}>
        <div>
            <span>30/ 46 Avenue du Vieux</span>
            <span>Chemin de St Denis 92390</span>
            <span>Villeneuve-la- Garenne</span>
        </div>
        <div>
            <span>{MAIN_PHONE}</span>
            <span>{SECONDARY_PHONE}</span>
            <span>{MAIN_EMAIL}</span>
        </div>
    </footer>
}

export default FooterComponent;