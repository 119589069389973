import { h } from 'preact';
import style from './style.css';

const MenuButton = (props) => {
    return (
        <div class={`${style.menuButton} ${props.isMenuVisible ? style.active : ''}`}>
            <div class={style.topLine}> </div>
            <div class={style.middleLine}> </div>
            <div class={style.bottomLine}> </div>
        </div>
    )
};

export default MenuButton;
