import style from './style.css';
import { Link } from "preact-router/match";
import { useState } from 'preact/hooks';
import { getCurrentUrl } from "preact-router";

const MenuContainer = (props) => {
    const currentUrl = getCurrentUrl();
    const isWindowsPageOpened = currentUrl.includes('windows');
    const sideUrlList = {
        pvc: '/windows?type=pvc',
        wood: '/windows?type=wood',
        aluminium: '/windows?type=aluminium',
        steel: '/windows?type=steel'
    }
    const [isSideOpened, setSideOpened] = useState(isWindowsPageOpened);

    const toggleSideMenu = () => {
        setSideOpened(!isSideOpened);
    }
    return (
        <div className={style.menuContainer}>
            <nav>
                <Link onClick={() => props.onNavigate()} activeClassName={style.active} href="/">Domicile</Link>
                <Link onClick={toggleSideMenu}>Fenêtres
                    <img
                        className={`${style.triangle} ${isSideOpened ? style.triangleOpened : ''}`}
                        alt="Arrow"
                        src="./../../assets/images/main/menu-arrow.svg"
                    />
                </Link>

                <div className={`${style.secondaryMenu} ${isSideOpened ? '' : style.hidden}`}>
                    <Link
                        onClick={() => props.onNavigate()}
                        class={sideUrlList.pvc === currentUrl ?  style.active : null}
                        href={sideUrlList.pvc}>
                        PVC
                    </Link>

                    <Link
                        onClick={() => props.onNavigate()}
                        class={sideUrlList.wood === currentUrl ?  style.active : null}
                        href={sideUrlList.wood}>
                        Bois
                    </Link>

                    <Link
                        onClick={() => props.onNavigate()}
                        class={sideUrlList.aluminium === currentUrl ?  style.active : null}
                        href={sideUrlList.aluminium}>
                        Aluminium
                    </Link>

                    <Link
                        onClick={() => props.onNavigate()}
                        class={sideUrlList.steel === currentUrl ?  style.active : null}
                        href={sideUrlList.steel}>
                        Acier
                    </Link>
                </div>

                <Link onClick={() => props.onNavigate()} activeClassName={style.active} href="/doors">Portes</Link>
                <Link onClick={() => props.onNavigate()} activeClassName={style.active} href="/blinds">Stores</Link>
                <Link onClick={() => props.onNavigate()} activeClassName={style.active} href="/gates">Portes de Garage</Link>
                <Link onClick={() => props.onNavigate()} activeClassName={style.active} href="/accessories">Accessoires</Link>
                <Link onClick={() => props.onNavigate()} activeClassName={style.active} className={style.contacts} href="/contacts">Contacts</Link>
            </nav>
        </div>
    )
};

export default MenuContainer;
