import { h } from 'preact';
import { Router } from 'preact-router';

import Header from './header';
import FooterComponent from "./footer"

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Windows from "../routes/windows";
import Doors from "../routes/doors";
import Blinds from "../routes/blinds";
import Gates from "../routes/gates";
import Accessories from "../routes/accessories";
import Product from "../routes/product";
import ContactsPage from "../routes/contacts";

const App = () => (
	<div id="app">
		<Header />

		<Router>
			<Home path="/" />
			<Windows path="/windows" />
			<Product path="/windows/:id" />
			<Doors path="/doors" />
			<Product path="/doors/:id" />
			<Blinds path="/blinds" />
			<Product path="/blinds/:id" />
			<Gates path="/gates" />
			<Product path="/gates/:id" />
			<Accessories path="/accessories" />
			<ContactsPage path="/contacts" />
		</Router>

		<FooterComponent/>
	</div>
)

export default App;
