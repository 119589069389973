import { Link } from 'preact-router/match';
import style from './style.css';
import MenuButton from "../menu-button";
import MenuContainer from "../side-menu";
import { useState } from "preact/hooks";

const Header = () => {
	let [isMenuOpen, setMenuOpen] = useState(false);

	const onMenuButtonCLick = () => {
		setMenuOpen(!isMenuOpen);
	}

	return (
		<>
			<header className={style.header}>
				<Link href="/">
					<img src="./../assets/images/main/logo.png" alt="logo" className={style.logo}/>
				</Link>
				<nav>
					<Link activeClassName={style.active} href="/" >Domicile</Link>
					<Link activeClassName={style.active} href="/windows">Fenêtres</Link>
					<Link activeClassName={style.active} href="/doors">Portes</Link>
					<Link activeClassName={style.active} href="/blinds">Stores</Link>
					<Link activeClassName={style.active} href="/gates">Portes de Garage</Link>
					<Link activeClassName={style.active} href="/accessories">Accessoires</Link>
					<Link activeClassName={style.active} className={style.contacts} href="/contacts">Contacts</Link>
				</nav>

				<div className={ style.menuButtonContainer } onClick={() => onMenuButtonCLick()}>
					<MenuButton isMenuVisible={isMenuOpen}> </MenuButton>
				</div>
			</header>
			{
				isMenuOpen ?
					<div className={style.menuContainer}>
						<div className={style.menuBackdrop} onClick={() => onMenuButtonCLick()}> </div>
						<MenuContainer onNavigate={onMenuButtonCLick}> </MenuContainer>
					</div> : null
			}
		</>
	)
}

export default Header;
